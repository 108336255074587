/**
 * Locale keys for error messages
 */
export default {
  /** Apply Location required */
  APPLY_LOC_MAX: 'applyLocationMax',

  /** Checkbox required */
  CHECK_REQUIRED: 'checkRequired',

  /** Confirm signup failed (token incorrect) */
  CONFIRM_SIGNUP_FAILED: 'confirmSignupFailed',

  /** Job doesn't exist */
  E100001: 'jobNotExist',

  /** Job application cannot be saved when job is closed */
  E100002: 'jobCannotSaveWhenClodes',

  /** Job application cannot be saved when job suspended */
  E100004: 'jobCannotSaveWhenSuspended',

  /** Job application cannot be saved when company suspended */
  E100005: 'jobCannotSaveWhenCompanySuspended',

  /** Job application contains addresses that is not provided by your employer */
  E100006: 'jobInvalidAddresses',

  /** Already Applied */
  E100007: 'jobAlreadyApplied',

  /** Job application must at least contain one address to apply */
  E100008: 'jobMustHaveAddress',

  /** Must provide at least a valid email or phone number */
  E100009: 'provideValidEmailOrTel',

  /** Must provide user id */
  E100010: 'provideUserId',

  /** maximum limit is 30 */
  E100011: 'maxLimit30',

  /** `_id` in arguments does not exists */
  E100012: 'idNotExist',

  /** Updating applicant info that not belong to the user */
  E100013: 'invalidApplicant',

  /** Level of spoken-skill is out of range */
  E100014: 'invalidSpokenSkillLevel',

  /** Level of written-skill is out of range */
  E100015: 'invalidWrittenSkillLevel',

  /** Start time of availability is out of range */
  E100016: 'invalidStartTime',

  /** End time of availability is out of range */
  E100017: 'invalidEndTime',

  /** Start date should be before than End date for any work experience. */
  E100018: 'startDateBeforeEndDate',

  /** Job cannot be bookmarked */
  E100019: 'jobCannotBookmark',

  /** Job has not been bookmarked */
  E100020: 'jobHasNotBookmark',

  /** The number of job alert is already reach the limit */
  E100032: 'reachAlertLimit',

  /** The user is not an applicant */
  E100033: 'invalidApplicant',

  /** Job alert cannot be empty */
  E100034: 'jobAlertEmpty',

  /** Job alert ids cannot be empty */
  E100035: 'jobAlertIdsEmpty',

  /** Invalid job-alert query payload. */
  E100036: 'jobAlertInvalidPayload',

  /** Unsupported Authentication Channel */
  E100037: 'unsupportAuthChannel',

  /** Login Failed */
  E100038: 'loginFail',

  /** The job state cannot be applied */
  E100039: 'jobStateNotApplied',

  /** Invalid OTP token */
  E100040: 'invalidOtpToken',

  /** Invalid OTP token */
  E100047: 'telephoneNotExist',

  /* Account is pending to be deleted, therefore not authorized for the mutation */
  E100049: 'deletedAccountCannotAccess',

  /** Email address invalid */
  EMAIL_INVALID: 'emailInvalid',

  /** Email address already registered */
  EMAIL_REGISTERED: 'emailRegistered',

  /** Email successfully resent */
  EMAIL_RESENT: 'emailResent',

  /** File not selected */
  FILE_NOT_SELECTED: 'fileNotSelected',

  /** File size exceeds limit */
  FILE_SIZE_EXCEED_LIMIT: 'fileSizeExceedLimit',

  /** File type not accepted */
  FILE_TYPE_NOT_ACCEPTED: 'fileTypeNotAccepted',

  /** Selected list items count lower than limit */
  LIST_COUNT_BELOW_MIN: 'listCountBelowMin',

  /** Selected list requires at least one item */
  LIST_COUNT_BELOW_ONE: 'listCountBelowOne',

  /** Selected list items count greater than limit */
  LIST_COUNT_EXCEED_MAX: 'listCountExceedMax',

  /** Selected list invalid */
  LIST_INVALID: 'listInvalid',

  /** Login failed (email / password incorrect) */
  LOGIN_FAILED: 'loginFailed',

  /** Invalid OTP */
  LOGIN_OTP_INVALID: 'loginOtpInvalid',

  /** Password already set */
  PASSWORD_ALREADY_SET: 'passwordAlreadySet',

  /** Password invalid */
  PASSWORD_INVALID: 'passwordInvalid',

  /** Passwords not match */
  PASSWORDS_NOT_MATCH: 'passwordsNotMatch',

  /** Request error */
  REQUEST_ERROR: 'requestError',

  /** Field required */
  REQUIRED: 'required',

  /** Set password failed */
  SET_PASSWORD_FAILED: 'setPasswordFailed',

  /** Telephone number invalid */
  TELEPHONE_INVALID: 'telephoneInvalid',

  /** Token expired */
  TOKEN_EXPIRED: 'tokenExpired',

  /** Upload failed */
  UPLOAD_FAILED: 'uploadFailed',
}
