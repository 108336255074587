<template>
  <main>
    <div class="overlay" @click="onClose" />
    <div class="side-menu">
      <svg-close class="svg-fill b__close" />
      <!--   Seeker avatar and name   -->
      <button class="profile__info" @click="onItemClick(0)">
        <div class="profile__logo">
          <svg-avatar v-if="!thumbnail" />
          <img
            v-if="thumbnail"
            loading="lazy"
            :alt="seekerName || 'Seeker logo'"
            :title="seekerName || 'Seeker logo'"
            :src="thumbnail"
          />
        </div>
        <!-- Seeker name -->
        <h3 class="profile__name">{{ seekerName }}</h3>
      </button>
      <template v-for="(item, i) in items">
        <button :key="`MenuItem${i}`" class="option__btn" @click="onItemClick(i)">
          <h3>{{ item.title }}</h3>
        </button>
      </template>
      <div class="side-menu__footer">
        <app-footer-store-links />
        <div v-show="languageLink.length" class="language-link">
          <svg-language class="svg-fill" />
          <app-link v-for="(link, i) in languageLink" :key="`FooterTopLink${i}`" class="ftr__link" v-bind="link" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AppFooterStoreLinks from '../footer/appFooterStoreLinks'
import AppLink from '../appLink'
import ProfileMixin from '../../mixins/profile'
import SvgAvatar from '../../assets/svg/avatar.svg?inline'
import SvgClose from '../../assets/svg/close.svg?inline'
import SvgLanguage from '../../assets/svg/language.svg?inline'

export default {
  components: {
    'app-footer-store-links': AppFooterStoreLinks,
    'app-link': AppLink,
    'svg-avatar': SvgAvatar,
    'svg-close': SvgClose,
    'svg-language': SvgLanguage,
  },
  computed: {
    languageLink() {
      return this.altLocaleLinks
    },
    /**
     * Seeker name
     */
    seekerName() {
      return this.name || this.telephone
    },
  },
  methods: {
    /**
     * close the side menu
     */
    onClose() {
      this.$emit('close')
    },
    /**
     * Emit menu item click event
     * w/ item index
     */
    onItemClick(index) {
      if (index >= 0 && index < this.items.length) {
        this.$emit('click', index)
      }
    },
  },
  mixins: [ProfileMixin],
  props: {
    /**
     * Menu items
     */
    items: {
      default: () => [],
      type: Array,
    },
  },
}
</script>

<style scoped>
.overlay {
  @apply inset-0 fixed z-30;
  @apply bg-transparent pointer-events-none;
  transition: background-color 0.5s linear;
}

.side-menu {
  @apply inset-0 fixed z-30;
  @apply bg-grey-bg flex flex-col;
  transform: translate3d(115%, 0, 0);
  -webkit-transform: translate3d(115%, 0, 0);
  transition: transform 0.5s;
}

.b__close {
  @apply text-white absolute;
  @apply w-8 h-8 pointer-events-none;
  top: 8px;
  left: -40px;
}

.profile__info {
  @apply flex outline-none;

  & .profile__logo {
    @apply m-4 rounded-full bg-grey-mlight overflow-hidden;
    @apply border border-solid border-pink-disabled;
    @apply w-12 h-12;

    & img {
      @apply h-full object-cover;
    }
  }

  & .profile__name {
    @apply my-4 inline-block align-middle;
    @apply text-black h-12 font-medium;
    line-height: 48px;
  }
}

.option__btn {
  @apply text-left outline-none;

  & h3 {
    @apply ml-4 inline-block align-middle;
    @apply text-black h-12;
    line-height: 48px;
  }
}

.side-menu__footer {
  @apply absolute inset-x-0 bottom-0;
  @apply p-4 flex;
  height: 80px;

  & .language-link {
    @apply flex items-center ml-4;
    @apply text-black;

    & svg {
      @apply mr-2 text-grey-900;
      width: 18px;
      height: 16px;
    }
  }
}
</style>
